'use client';

import dynamic from 'next/dynamic';

import * as Styled from './layout.styles';

const TopContentLayout = dynamic(() => import('./TopContent'), { ssr: false });
const BottomContentLayout = dynamic(() => import('./BottomContent'), {
  ssr: false,
});

/*
 * Entry point that makes Layout "client side only".
 */
export function ClientOnlyStaticRootLayout({ children }) {
  return (
    <Styled.AppLayout>
      <TopContentLayout />
      {children}
      <BottomContentLayout />
    </Styled.AppLayout>
  );
}
